import { React, useEffect, useState } from "react";
import NavBar from "./NavBar.js";
import Footer from "./FooterPC.js";
import FooterMobile from "./FooterMobile.js";
import ContactForm from "./ContactForm.js";

import { Container } from "react-bootstrap";
//import "../styles/App.css";
  

const Layout = ({ children }) => {
  // Functions that handles reading window size
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
	  function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className="site">
      <NavBar />
      <Container className='site-content'>{children}</Container>
    </div>
  );
};

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight}  
}

export default Layout;
