import { React, useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Stack, Container, Button, Modal, Form } from "react-bootstrap";
import aMomentAtATime from "../music/A Moment at a Time Master.wav";
import iAssaliant from "../music/I Assailant Master.wav";
import onMyMind from "../music/On My Mind Master.wav";
import standaloneComplex from "../music/Standalone Complex Master.wav";
import "../styles/SinglePage.css";
import emailjs from "@emailjs/browser";

import ScrollTopBtn from "../components/ScrollTopBtn.js";

function Home() 
{
  const [modalShow, setModalShow] = useState(false);
  const [template, setTemplate] = useState('');

  const [isAboutVisible, setIsAboutVisible] = useState(false);
  const [isPortfolioVisible, setIsPortfolioVisible] = useState(false);
  const [isServiceVisible, setIsServiceVisible] = useState(false);

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const form = useRef();

  function sendEmail(e)
  {
    e.preventDefault();
    handleClose();
    if (template === "Website Issues")
    {
      emailjs.sendForm('website-gmail', 'website-issue69', form.current, 'vXdxEcA_bwfJK8Lvc').then((result) => {
      }, (error) => {
      });
    }
    else
    {
      // TODO: Change public key to Nate's account!
      emailjs.sendForm('service_0fqnqu8', 'template_yvdp7mf', form.current, 'jPIwCYB1UKzKPAsio').then((result) => {
      }, (error) => {
      });
    }
  }

  const toggleVisibility = () => {
    //console.log(window.scrollY);
    if (window.scrollY > 150 && window.scrollY < 790)
    {
      setIsAboutVisible(true);
    }
    else if (window.scrollY > 790 && window.scrollY < 1290)
    {
      setIsPortfolioVisible(true);
    }
    else if (window.scrollY > 1290)
    {
      setIsServiceVisible(true);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    }
  }, []);

  return(
    <>
      <Container className="home-container">
        {/*Cause I hate this language, the Modal is here on every JS file that needs it*/}
        <Modal className="contact-form" size="lg" show={modalShow} onHide={handleClose} centered>
          <Modal.Header closeButton closeVariant="white">
            <h4>Contact Us</h4>
          </Modal.Header>

          <Modal.Body>
            <Form ref={form} onSubmit={sendEmail}>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label className="form-label">Name</Form.Label>
                <Form.Control type="name" placeholder="Enter Name" name="name" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="form-label">Email Address</Form.Label>
                <Form.Control type="email" placeholder="Enter Email" name="email"/>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="form-label">Subject</Form.Label>
                <Form.Select size="lg" name="subject" onChange={e => setTemplate(e.target.value)}>
                  <option selected disabled value="" />
                  <option value="Book Session">Book Session</option>
                  <option value="Schedule Session">Schedule Session</option>
                  <option value="Ask Question">Ask a Question</option>
                  <option value="Website Issues">Website Issue</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="form-label">Message</Form.Label>
                <Form.Control as="textarea" rows={4} name="message" />
              </Form.Group>

              <Button variant="primary" type="submit" style={{fontSize: "2.5vh"}}>
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Stack id="home" gap={3} className="col-md-8 mx-auto">
          <strong>Professional Audio Mixing for Rock, Metal, and Indie Rock Artists.</strong>
          <p>
            Interested? Book a mixing session or schedule a session.
          </p>
          <div>
            <Button id="schedule-button" variant="primary" size="xxl" onClick={handleShow}>Schedule Booking or Session</Button>
          </div> 
          <hr />
          <strong id="about">About Dead Fret Productions</strong>
          { isAboutVisible ? 
            <div className="transform-content">
              <p>
                Here at Dead Fret Productions we provide professional mixing services for Rock, Metal, and Indie Rock Artists.
                We strive to listen and understand your specific needs so you have peace of mind knowing your art and vision is in good hands.
              </p>
              <p>
                We will yes our expertise to bring out the subtle nuances of your art in a way that is competitive with other products and leaves you feeling confident about 
                releasing your material.
              </p>
            </div> : "" }
          <hr />
          <strong id="portfolio">Portfolio</strong>
          { isPortfolioVisible ?
            <>
              {<div className="portfolio-box">
                <Row className="portfolio-row">
                  <Col>
                    <h4>A Moment at a Time - Recorded by Dead Fret Productions - Mixed and Mastered by Dead Fret Productions</h4>
                    <audio className="audio-player" controls controlsList="nodownload">
                      <source src={aMomentAtATime} />
                    </audio>
                  </Col>
                </Row>
                <Row className="portfolio-row">
                  <Col>
                    <h4>I Assailant - Recorded at 456 Recordings - Mixed and Mastered by Dead Fret Productions</h4>
                    <audio className="audio-player" controls controlsList="nodownload">
                      <source src={iAssaliant} />
                    </audio>
                  </Col>
                </Row>
                <Row className="portfolio-row">
                  <Col>
                    <h4>On My Mind - Recorded by Dead Fret Productions - Mixed and Mastered by Dead Fret Productions</h4>
                    <audio className="audio-player" controls controlsList="nodownload">
                      <source src={onMyMind} />
                    </audio>
                  </Col>
                </Row>
                <Row className="portfolio-row">
                  <Col>
                    <h4>Standalone Complex - Recorded at 456 Recordings - Mixed and Mastered by Dead Fret Productions</h4>
                    <audio className="audio-player" controls controlsList="nodownload">
                      <source src={standaloneComplex} />
                    </audio>
                  </Col>
                </Row>
              </div>}
            </> : "" }
          <hr />
          <strong id="services">Services & Rates</strong>
          { isServiceVisible ? 
            <div className="transform-content">
              <div className="service-rates">
                Dead Fret Production's rates are a flat $300 dollars per mix.
              </div>
              <div className="service-rates">
                Up to 3 revisions will be allowed per mix.
              </div>
              <div className="service-rates">
                New clients can have their first song mixed with no cost up front. Payment is to be received in full upon satisfactory completion of their first mix.
              </div>
              <div className="service-rates">
                After the first mix all future mixes are to be paid half up front and half after the production is completed.
              </div>
              <div className="service-rates">
                Once full payment is received a .wav file of the final product will be sent to the artist.
              </div>
            </div> : "" }
          <hr />
          <strong>Ready to get started?</strong>
          <div className="contact-container">
            <Button id="contact-button" onClick={handleShow}>Contact Us</Button>
          </div>
        </Stack>
        <ScrollTopBtn />
      </Container>
    </>
  );	
}

export default withRouter(Home);