import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import firebase from "firebase/compat/app";

firebase.initializeApp({
  apiKey: "AIzaSyAULOBUDAqCmVsKkEzLr3oEubJOw_lbuBU",
  authDomain: "deadfretproductions-web.firebaseapp.com",
  projectId: "deadfretproductions-web",
  storageBucket: "deadfretproductions-web.appspot.com",
  messagingSenderId: "806820369697",
  appId: "1:806820369697:web:2847859299ead6c60d5a60",
  measurementId: "G-ST8Y4E178N"
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


/*
  className="col-md-8 mx-auto"
*/
