import {React, useEffect, useState} from "react";
import { BiArrowFromBottom } from "react-icons/bi";

import "../styles/SinglePage.css";

const ScrollTopBtn = () =>
{
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300)
    {
      setIsVisible(true);
    }
    else
    {
      setIsVisible(false);
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    }
  }, []);

  // Creating two buttons, one that one true gets 100% opacity, the other gets 0 opacity which creates a transition.
  return(
    <>
      {isVisible ?
        <button type="button" onClick={scrollToTop} id="scroll-to-top-btn" style={{opacity: "1"}}>
          <BiArrowFromBottom className="scroll-to-top-icon" aria-hidden="true" />
        </button> : <button type="button" onClick={scrollToTop} id="scroll-to-top-btn" style={{opacity: "0"}}>
          <BiArrowFromBottom className="scroll-to-top-icon" aria-hidden="true" />
        </button>
      }
    </>
  )
};

export default ScrollTopBtn;