import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

//import './styles/App.css';

import Home from "./pages/Home";
import Layout from "./components/Layout";
import React from "react";

import "bootstrap/dist/css/bootstrap.css";

function App() {
  return (
    <Router>
        <Route exact path="/">
        <Layout>
          <Home />
        </Layout>
      </Route>
    </Router>
  );
}

export default App;