import React, { useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import { Nav, Navbar, Button, Modal, Form } from "react-bootstrap";
import DeadFretLogo from "../images/Dead Fret.jpg";
import emailjs from "@emailjs/browser";
import "../styles/SinglePage.css";

const NavBar = () => {
  const [modalShow, setModalShow] = useState(false);
  const [template, setTemplate] = useState('');

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const form = useRef();

  function sendEmail(e)
  {
    e.preventDefault();
    handleClose();
    if (template === "Website Issues")
    {
      emailjs.sendForm('website-gmail', 'website-issue69', form.current, 'vXdxEcA_bwfJK8Lvc').then((result) => {
        //console.log(result.text);
      }, (error) => {
        //console.log(error.text);
      });
    }
    else
    {
      // TODO: Change public key to Nate's account!
      emailjs.sendForm('service_0fqnqu8', 'template_yvdp7mf', form.current, 'jPIwCYB1UKzKPAsio').then((result) => {
        //console.log(result.text);
      }, (error) => {
        //console.log(error.text);
      });
    }
  }

  return (
    <Navbar bg="black" variant="dark" expand="sm" collapseOnSelect>
      <Container id="footer-container">
        {/*Cause I hate this language, the Modal is here on every JS file that needs it*/}
        <Modal className="contact-form" size="lg" show={modalShow} onHide={handleClose} centered>
          <Modal.Header closeButton closeVariant="white">
            <h4>Contact Us</h4>
          </Modal.Header>

          <Modal.Body>
            <Form ref={form} onSubmit={sendEmail}>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label className="form-label">Name</Form.Label>
                <Form.Control type="name" placeholder="Enter Name" name="name" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="form-label">Email Address</Form.Label>
                <Form.Control type="email" placeholder="Enter Email" name="email"/>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="form-label">Subject</Form.Label>
                <Form.Select size="lg" name="subject" onChange={e => setTemplate(e.target.value)}>
                  <option selected disabled value="" />
                  <option value="Book Session">Book Session</option>
                  <option value="Schedule Session">Schedule Session</option>
                  <option value="Ask Question">Ask a Question</option>
                  <option value="Website Issues">Website Issue</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="form-label">Message</Form.Label>
                <Form.Control as="textarea" rows={4} name="message" />
              </Form.Group>

              <Button variant="primary" type="submit" style={{fontSize: "2.5vh"}}>
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Navbar.Brand>
          <img className="nav-logo" src= {DeadFretLogo} alt="Dead Fret Productions Logo"/>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="flex-grow-1 justify-content-evenly">
            <Nav.Link href="#about">About</Nav.Link>
            <Nav.Link href="#portfolio">Portfolio</Nav.Link>
            <Nav.Link href="#services">Services</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
